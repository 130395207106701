import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Title, Image, Box } from "../utils/system";
import Blocks from "../components/Blocks";

const Project = ({ data }) => {
  const { title, _rawContent, image } = data.sanityProject;
  return (
    <Layout>
      <Title
        fontSize={[5, 6, 6]}
        px={[5, 6, 6]}
        textAlign="center"
        mb={0}
        py={6}
      >
        {title}
      </Title>
      <Image image={image.asset.gatsbyImageData} alt={title} />
      <Box px={[3, 5, 7]} py={[4, 6]}>
        <Blocks blocks={_rawContent} />
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query GetProject($id: String!) {
    sanityProject(_id: { eq: $id }) {
      title
      _rawContent
      image {
        asset {
          gatsbyImageData(aspectRatio: 1.9)
        }
      }
    }
  }
`;

export default Project;
